import AdminHeader from "../../layouts/admin-header";
import AdminFooter from "../../layouts/admin-footer";
import AdminNavBar from "../../layouts/admin-nav-bar";
import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../loader";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { API_URL } from "../../../config/constant";
import Swal from "sweetalert2";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDropzone } from "react-dropzone";
import htmlEditButton from "quill-html-edit-button"; // Import the HTML edit button module

// Register the module with Quill
ReactQuill.Quill.register("modules/htmlEditButton", htmlEditButton);

export default function AddBlog({ menuAccess }) {
  const [loader, setLoader] = useState(false);
  const [showApplyButton, setShowApplyButton] = useState(false);
  const [featureBlog, setFeatureBlog] = useState(false);
  const [description, setDescription] = useState("");
  const [categories, setCategories] = useState([]);

  const { id } = useParams();
  const navigate = useNavigate();
  const [isEditMode, setIsEditMode] = useState(!!id);

  const [error, setError] = useState("");
  const [preview, setPreview] = useState(null);

  const [category, setCategory] = useState("");
  const [readTime, setReadTime] = useState("");
  const [title, setTitle] = useState("");
  const [applyNowLink, setApplyNowLink] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [blogImage, setBlogImage] = useState(null);

  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [readTimeError, setReadTimeError] = useState(false);
  const [blogImageError, setBlogImageError] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [".jpeg", ".jpg", ".png", ".gif", ".bmp", ".webp"],
    },
    multiple: false,
    onDrop: (acceptedFiles) => {
      setError(""); // Reset error state
      setLoader(true); // Show loader

      if (acceptedFiles.length > 0) {
        const file = acceptedFiles[0];
        const img = new Image();

        img.onload = () => {
          setLoader(false); // Hide loader

          if (img.width >= 1080 && img.height >= 720) {
            setPreview(URL.createObjectURL(file)); // Show preview for UI
            setBlogImage(file); // Store the actual file instead of Blob URL
          } else {
            setError("Image resolution must be at least 1080×720 pixels.");
          }
        };

        img.src = URL.createObjectURL(file);
      }
    },
  });

  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["bold", "italic", "underline"],
    [{ align: [] }],
    ["link", "image"],
    ["clean"], // Add code block option for source code view
    ["html"], // Add HTML view option
  ];

  const modules = {
    toolbar: toolbarOptions,
    htmlEditButton: { debug: true }, // Enable HTML edit button with debug mode
  };

  // Function to edit the blog

  useEffect(() => {
    const fetchBlogData = async (blogId) => {
      setLoader(true);
      try {
        const response = await axios.get(`${API_URL}blog/blog/${blogId}`);
        const data = response.data.data[0];

        setCategory(data.category_id.toString());
        setReadTime(data.read_time.toString());
        setTitle(data.title);
        setDescription(data.content);
        setBlogImage(data.blog_image);
        setPreview(data.full_blog_image_url);
        setShowApplyButton(data.show_apply_now_button);
        setApplyNowLink(data.apply_nowbtn_link);
        setFeatureBlog(!!data.is_featured_blog);
        setMetaTitle(data.meta_title);
        setMetaDescription(data.meta_description);
      } catch (error) {
        Swal.fire("Error!", "Failed to fetch blog data.", "error");
      } finally {
        setLoader(false);
      }
    };

    if (id) {
      fetchBlogData(id);
    }
  }, [id]);

  const handleBlogDescription = (value) => {
    // console.log(value);
    setDescription(value);
  };

  const fetchCategoryList = async () => {
    setLoader(true);
    try {
      const response = await axios.get(`${API_URL}blog/categories`);
      const { data, status } = response?.data;
      if (status === 200) {
        setCategories(data);
      }
    } catch (error) {
      console.error("There was an error fetching the category list!", error);
    } finally {
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchCategoryList();
  }, []);

  const handleValidate = () => {
    let isFormValid = true;

    if (!title) {
      setTitleError(true);
      isFormValid = false;
    } else {
      setTitleError(false);
    }

    if (!description) {
      setDescriptionError(true);
      isFormValid = false;
    } else {
      setDescriptionError(false);
    }

    if (!category) {
      setCategoryError(true);
      isFormValid = false;
    } else {
      setCategoryError(false);
    }

    if (!readTime) {
      setReadTimeError(true);
      isFormValid = false;
    } else {
      setReadTimeError(false);
    }

    if (!blogImage) {
      setBlogImageError(true);
      isFormValid = false;
    } else {
      setBlogImageError(false);
    }

    return isFormValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoader(true);
    setError("");

    if (!handleValidate()) {
      setLoader(false);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("category_id", category);
      formData.append("read_time", readTime);
      formData.append("title", title);
      formData.append("content", description);
      formData.append("show_apply_now_button", showApplyButton);
      formData.append("apply_nowbtn_link", applyNowLink);
      formData.append("meta_title", metaTitle);
      formData.append("meta_description", metaDescription);
      formData.append("is_featured_blog", featureBlog);

      if (blogImage instanceof File) {
        formData.append("blog_image", blogImage); // New image upload
      } else if (id) {
        formData.append("blog_image", null); // First append null to reset
        if (typeof blogImage === "object" && blogImage !== null) {
          formData.append("blog_image", blogImage); // Then append actual object
        }
      }

      const url = id
        ? `${API_URL}blog/edit-blog/${id}`
        : `${API_URL}blog/add-blog`;
      const method = id ? "put" : "post";

      const response = await axios[method](url, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      Swal.fire({
        icon: "success",
        title: "Success!",
        text: id ? "Blog updated!" : "Blog added!",
      }).then(() => {
        if (id) {
          navigate("/admin/manageBlog/list");
        } else {
          navigate("/admin/manageBlog/list");
          handleReset();
        }
      });
    } catch (error) {
      Swal.fire("Error!", id ? "Update failed." : "Add failed.", "error");
    } finally {
      setLoader(false);
    }
  };

  const handleReset = () => {
    setBlogImage(null);
    setPreview(null);
    setCategory("");
    setReadTime("");
    setTitle("");
    setDescription("");
    setMetaDescription("");
    setMetaTitle("");
    setShowApplyButton(false);
    setApplyNowLink("");
  };
  return (
    <>
      <ToastContainer />
      {loader && <Loader />}
      <div className="layout-wrapper">
        <div className="layout-container">
          <AdminNavBar menuAccess={menuAccess} />
          <div className="adminMain-wrapper">
            <AdminHeader />
            <div className="mainContent">
              <div className="topHeadings mb-4">
                <h3>Add Blog</h3>
              </div>
              <div className="contentBlocks" style={{ marginBottom: "20px" }}>
                <div className="sectionTable">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-xs-12">
                        <select
                          className="form-control"
                          value={category}
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          {categories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </select>
                        {categoryError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "5px",
                              paddingLeft: "10px",
                            }}
                          >
                            Category is required.
                          </p>
                        )}
                      </div>

                      <div className="col-lg-6 col-md-6 col-xs-12">
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Read time (mins)"
                          value={readTime}
                          onChange={(e) => setReadTime(e.target.value)}
                          min={1}
                        />
                        {readTimeError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "5px",
                              paddingLeft: "10px",
                            }}
                          >
                            Read time is required.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contentBlocks" style={{ marginBottom: "20px" }}>
                <div className="sectionTable">
                  <div className="container-fluid">
                    <div className="row">
                      <div
                        className="col-lg-12 col-md-12 col-xs-12"
                        style={{ minHeight: "200px" }}
                      >
                        {preview ? (
                          <div
                            style={{ textAlign: "center", marginTop: "10px" }}
                          >
                            <img
                              src={preview}
                              alt="Preview"
                              style={{
                                width: "100%",
                                borderRadius: "5px",
                                maxHeight: "200px",
                                objectFit: "cover",
                              }}
                            />
                            <button
                              type="button"
                              onClick={() => {
                                setPreview(null);
                                setBlogImage(null);
                              }}
                              className="blog-image-remove"
                            >
                              ☓
                            </button>
                          </div>
                        ) : (
                          <div
                            {...getRootProps()}
                            className="form-control text-center p-3 border border-dashed"
                            style={{ cursor: "pointer", minHeight: "200px" }}
                          >
                            <input
                              {...getInputProps()}
                              onChange={(event) => {
                                const file = event.target.files[0];
                                if (file) {
                                  const imageUrl = URL.createObjectURL(file);
                                  setPreview(imageUrl);
                                  setBlogImage(file);
                                }
                              }}
                            />
                            <p style={{ marginTop: "-10px" }}>
                              Drag & drop an image here, or click to select with
                              minimum dimensions of 1080×720 pixels.
                            </p>
                          </div>
                        )}

                        {blogImageError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "5px",
                              textAlign: "center",
                            }}
                          >
                            Blog image is required.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contentBlocks" style={{ marginBottom: "20px" }}>
                <div className="sectionTable">
                  <div className="container-fluid">
                    <div className="row">
                      <div
                        className="col-lg-12"
                        style={{ marginBottom: "20px" }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Blog Title"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                        {titleError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "5px",
                              paddingLeft: "10px",
                            }}
                          >
                            Title is required.
                          </p>
                        )}
                      </div>
                      <div className="col-lg-12">
                        <ReactQuill
                          value={description}
                          onChange={(value) => {
                            setDescription(value);
                            handleBlogDescription(value);
                          }}
                          placeholder="Blog description"
                          theme="snow"
                          modules={modules}
                        />
                        {descriptionError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "5px",
                              paddingLeft: "10px",
                            }}
                          >
                            Description is required.
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contentBlocks" style={{ marginBottom: "20px" }}>
                <div className="sectionTable">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="checkbox"
                          checked={showApplyButton}
                          onChange={() => setShowApplyButton(!showApplyButton)}
                        />{" "}
                        Show Apply Now Button
                        {showApplyButton && (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter URL"
                            value={applyNowLink}
                            onChange={(e) => setApplyNowLink(e.target.value)}
                          />
                        )}
                      </div>
                      <div className="col-lg-6">
                        <input
                          type="checkbox"
                          onChange={() => setFeatureBlog(!featureBlog)}
                        />{" "}
                        Featured blog
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="contentBlocks" style={{ marginBottom: "20px" }}>
                <div className="sectionTable">
                  <div className="container-fluid">
                    <div className="row">
                      <div className="col-lg-6">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Meta tag title"
                          value={metaTitle}
                          onChange={(e) => setMetaTitle(e.target.value)}
                        />
                        <div className="col-lg-6 col-md-6 col-xs-6">
                          <div
                            className="form-group"
                            style={{
                              display: "Flex",
                              justifyContent: "flex-start",
                              marginTop: "20px",
                            }}
                          >
                            <button type="reset" className="btn btn-secondary">
                              Reset
                            </button>
                            &nbsp;
                            <button
                              type="button"
                              className="btn btn-primary"
                              disabled={loader}
                              onClick={handleSubmit}
                            >
                              {loader ? "Loading..." : "Submit"}
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <ReactQuill
                          value={metaDescription}
                          onChange={setMetaDescription}
                          placeholder="Meta blog description"
                          theme="snow" // Snow theme provides a toolbar
                          modules={modules}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AdminFooter />
          </div>
        </div>
      </div>
    </>
  );
}
